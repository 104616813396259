import { switchActiveElement } from "../utils/activeElem";

// Tabbed content.
document.querySelectorAll<HTMLAnchorElement>("[data-tabs]").forEach((elem) => {
    elem.addEventListener("click", (event) => {
        event.preventDefault();
        const target = document.querySelector<HTMLElement>(elem.href || "");
        const block = elem.dataset.tabs || "";
        switchActiveElement(elem, `${block}__tab`);
        if (target) {
            switchActiveElement(target, `${block}__pane`);
        }
    });
});

// Footer toggle behavior
document
    .querySelectorAll<HTMLElement>(".footer-nav-mobile__header")
    .forEach((elem) => {
        elem.addEventListener("click", () => {
            elem.parentElement?.classList.toggle(
                "footer-nav-mobile__block--active",
            );
        });
    });

const accordionToggle = (
    event: MouseEvent | KeyboardEvent,
    elem: HTMLElement,
) => {
    event.preventDefault();
    elem.classList.toggle("accordion--is-active");
    elem.parentElement
        ?.querySelector(".accordion-target")
        ?.classList.toggle("accordion-target--is-active");
};

// Accordion
document.querySelectorAll<HTMLElement>(".accordion").forEach((elem) => {
    elem.addEventListener("click", (event) => {
        accordionToggle(event, elem);
    });
    elem.addEventListener("keypress", (event) => {
        if (event.code === "Enter") {
            accordionToggle(event, elem);
        }
    });
});

// Search bar functionality
document.querySelectorAll(".search-nav__icon").forEach((elem) => {
    elem.addEventListener("click", () => {
        document
            .querySelector<HTMLElement>(".search-nav__search-input")
            ?.classList.toggle("hidden");
        document
            .querySelector<HTMLElement>(".search-nav__retailers")
            ?.classList.toggle("hidden");
        document
            .querySelector<HTMLElement>(".search-nav__promo")
            ?.classList.toggle("hidden");
    });
});

document.querySelectorAll(".search-results__clear-icon").forEach((elem) => {
    elem.addEventListener("click", () => {
        const input = document.querySelector<HTMLInputElement>(
            ".search-results__search-form--text",
        );
        if (input) {
            input.value = "";
        }
    });
});

// Utility pages
if (document.body.classList.contains("utility-page")) {
    // On load -- Tabs -- Add active class
    document
        .querySelector<HTMLElement>(".tabs__tab")
        ?.classList?.add("tabs__tab--is-active");
    document
        .querySelector<HTMLElement>(".tabs__pane")
        ?.classList?.add("tabs__pane--is-active");

    // Desktop
    // Onload -- left side nav -- Accordion -- add active class
    document
        .querySelector<HTMLElement>(".accordion__list-item--desktop .accordion")
        ?.classList?.add("accordion--is-active");
    document
        .querySelector<HTMLElement>(
            ".accordion__list-item--desktop .accordion-target",
        )
        ?.classList?.add("accordion-target--is-active");
}
