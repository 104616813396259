import React from "react";
import { connect } from "react-redux";
import { IReduxState } from "../../reducers.interfaces";
import { VariantHeroBlock } from "tsi-common-react/src/models/streamfield-blocks";
import { IImageURL, isoImageURL } from "tsi-common-react/src/models/nominals";
import { IOptionValues } from "tsi-common-react/src/models/catalogue.interfaces";

interface IOwnProps {
    defaultBackgroundImage: IImageURL | null;
    defaultMobileBackgroundImage: IImageURL | null;
    defaultImageTitle: string;
    variantHeroInfo?: VariantHeroBlock[];
}

interface IReduxProps {
    selectedValues: IOptionValues;
}

type IProps = IOwnProps & IReduxProps;

interface IState {}

class PDPHeroComponent extends React.Component<IProps, IState> {
    getHeroImage() {
        const defaultImage = {
            desktop: this.props.defaultBackgroundImage,
            mobile: this.props.defaultMobileBackgroundImage,
            alt: this.props.defaultImageTitle,
        };
        if (!this.props.variantHeroInfo || !this.props.selectedValues) {
            return defaultImage;
        }

        const imageToUse = this.props.variantHeroInfo.find((variantHero) => {
            return (
                this.props.selectedValues[
                    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                    variantHero.attribute as any as keyof IOptionValues
                ] === variantHero.value
            );
        });

        if (!imageToUse) {
            return defaultImage;
        }
        return {
            desktop: imageToUse.image.url,
            mobile: imageToUse.mobile_image?.url || null,
            alt: imageToUse.image.title,
        };
    }

    render() {
        const heroImg = this.getHeroImage();
        return (
            <picture className="pdp-hero__image">
                {heroImg.mobile && (
                    <source
                        srcSet={isoImageURL.unwrap(heroImg.mobile)}
                        media="(max-width: 768px)"
                    ></source>
                )}
                {heroImg.desktop && (
                    <img
                        src={isoImageURL.unwrap(heroImg.desktop)}
                        alt={heroImg.alt}
                    />
                )}
            </picture>
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IProps => {
    return { ...ownProps, selectedValues: state.configurator.ui.optionValues };
};

export const PDPHero = connect(mapStateToProps)(PDPHeroComponent);
