import React from "react";
import classNames from "classnames";
import { IMenuSnippetLinkWithImage } from "../menu.interface";

interface IProps {
    linksArray: IMenuSnippetLinkWithImage[];
    linkID: number;
}

interface IState {}

export class DropdownLinks extends React.Component<IProps, IState> {
    render() {
        const dropdownLinks = this.props.linksArray.map((obj, id) => {
            let badge: JSX.Element | null = null;
            if (obj.value.badge) {
                badge = (
                    <span>
                        <span className="menu-dropdown-badge menu-dropdown-badge--mobile">
                            {obj.value.badge.mobile_text}
                        </span>
                        <img
                            className={`menu-dropdown-badge menu-dropdown-badge--${obj.value.badge.position}`}
                            src={obj.value.badge.image.url}
                            alt={obj.value.badge.mobile_text}
                        />
                    </span>
                );
            }

            const blockClasses = classNames({
                "menu-dropdown-block": true,
                [`menu-dropdown-block--${id}`]: true,
            });

            return (
                <a
                    title={obj.value.image.title}
                    key={id}
                    href={obj.value.page.url}
                    className={blockClasses}
                    style={{ backgroundImage: `url('${obj.value.image.url}')` }}
                >
                    {obj.value.override_title}
                    {badge}
                </a>
            );
        });

        return (
            <div
                className="main-dropdown-menu__shop-links"
                key={this.props.linkID}
            >
                {dropdownLinks}
            </div>
        );
    }
}
