document
    .querySelectorAll<HTMLElement>(
        ".home-page-reviews__container--hover-active",
    )
    .forEach((elem) => {
        const hoverElem = elem.querySelector<HTMLElement>(
            ".home-page-reviews-hover",
        );
        const imageElem = elem.querySelector<HTMLElement>(
            ".home-page-reviews__image",
        );
        if (!hoverElem || !imageElem) {
            return;
        }
        elem.addEventListener("mouseenter", () => {
            hoverElem.style.opacity = "100";
            imageElem.style.filter = "blur(5px)";
        });
        elem.addEventListener("mouseleave", () => {
            hoverElem.style.opacity = "0";
            imageElem.style.filter = "blur(0px)";
        });
    });
