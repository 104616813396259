import { IMenuItem } from "./menu.interface";

/**
 * Return the menu configuration from the JSON embedded in the DOM.
 */
export const getMenuConfiguration = () => {
    const dataBlock = document.querySelector("[data-menu-cms]");
    if (!dataBlock) {
        return;
    }
    const menuJSON = dataBlock.getAttribute("data-menu-cms");
    return JSON.parse(menuJSON || "[]") as IMenuItem[];
};
