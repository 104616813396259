import Flickity from "flickity";

if (document.querySelector(".bed-bases-selection__content")) {
    const tabList = document.querySelector<HTMLElement>(
        ".bed-bases-selection__content",
    );
    if (tabList) {
        new Flickity(tabList, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
        });
    }
}
