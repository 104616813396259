import Flickity from "flickity";

const productListNav = document.querySelector(".product-list-nav__list");
if (productListNav) {
    new Flickity(productListNav, {
        watchCSS: true,
        draggable: true,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
    });
}
