import React from "react";
import { IMenuPromo } from "../menu.interface";

interface IProps {
    promoArrayEl: IMenuPromo;
}

interface IState {}

export class PromoAd extends React.Component<IProps, IState> {
    render() {
        let link;
        let imageContent: JSX.Element | null = null;
        let customHTMLContent: JSX.Element | null = null;

        if (
            this.props.promoArrayEl.value.page ||
            this.props.promoArrayEl.value.external_link
        ) {
            link = this.props.promoArrayEl.value.page
                ? this.props.promoArrayEl.value.page.url
                : this.props.promoArrayEl.value.external_link;
        }

        if (this.props.promoArrayEl.value.image !== null) {
            if (this.props.promoArrayEl.value.image.url) {
                imageContent = (
                    <div className="main-dropdown-menu-ad__image-container">
                        <img
                            src={this.props.promoArrayEl.value.image.url}
                            alt={this.props.promoArrayEl.value.image.title}
                        />
                    </div>
                );
            }
        }

        if (this.props.promoArrayEl.value.html) {
            customHTMLContent = (
                <div
                    className="main-dropdown-menu-ad__content"
                    dangerouslySetInnerHTML={{
                        __html: this.props.promoArrayEl.value.html,
                    }}
                ></div>
            );
        }

        if (link) {
            // Need link title since content is an image. See #13290
            return (
                <a href={link} className="main-dropdown-menu-ad">
                    {imageContent}
                    {customHTMLContent}
                </a>
            );
        }

        return (
            <div className="main-dropdown-menu-ad">
                {imageContent}
                {customHTMLContent}
            </div>
        );
    }
}
