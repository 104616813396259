// Import polyfills and setup error reporting system
import "./src/_bootstrap";
import { Provider, getServerData, getTemplate } from "@reactivated";
import { initPage } from "@thelabnyc/thelabui/src/index";
import Modal from "react-modal";
import {
    sendGTMUserHash,
    kuidListeners,
    trackPageView,
} from "tsi-common-react/src/utils/analytics";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { initWidgets } from "tsi-common-react/src/widgets";

// Remove no-js class from HTML tag
const htmlElem = document.querySelector("html");
if (htmlElem) {
    htmlElem.classList.remove("no-js");
}

// Load CSS
import "./src/styles/main.scss";

// Update the user hash in GTM on page load
window.onload = () => {
    sendGTMUserHash();
};
kuidListeners();

// Set tsi-common-react configuration
import "./src/localization";

// This is the main entry point for new-style thelab-ui / reactivated
// components (which support SSR)
initPage({
    Provider,
    getServerData,
    getTemplate,
}).catch((e) => {
    console.error(e);
});

// Set the root app element for react-modal
if (document.querySelector("body > .layout")) {
    Modal.setAppElement("body > .layout");
} else if (document.querySelector("#root")) {
    Modal.setAppElement("#root");
}

// Configure analytics
onDOMContentLoaded.on(() => {
    trackPageView();
});

// Load Site Components
initWidgets();

import "./src/ui/main-nav";
import "./src/ui/helpers";
import "./src/ui/lazyloading";
import "./src/ui/mattress-index";
import "./src/ui/product-list";
import "./src/ui/bed-bases";
import "./src/ui/reviews";

import "./src/Common/index";
import "./src/Checkout/index";
import "./src/ProductGrid/index";
import "./src/ProductDetail/index";
import "./src/Reviews/index";
