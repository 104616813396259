import React from "react";
import { IMenuCategoryLink, IMenuItem } from "../menu.interface";
import { PageLink } from "../components/main-menu-page-link";
import { DropdownLinks } from "../components/main-menu-dropdown-links";
import { CategoryLink } from "../components/main-menu-category-link";

interface IProps {
    cmsData: IMenuItem[];
    isMobile: boolean;
    modalIsOpen: boolean;
    openModal: (event: React.MouseEvent<HTMLElement>) => void;
    closeModal: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IStage {}

export class MenuLinks extends React.Component<IProps, IStage> {
    private buildCategoryLinks(menuItem: IMenuCategoryLink) {
        const links: JSX.Element[] = [];
        let index = 0;
        let key = 0;

        const link = (
            <CategoryLink
                isMobile={this.props.isMobile}
                modalIsOpen={this.props.modalIsOpen}
                linkObject={menuItem}
                openModal={this.props.openModal}
                key={menuItem.value.title}
            />
        );
        links.push(link);

        if (!this.props.isMobile || menuItem.value.sub_nav.length <= 0) {
            return links;
        }

        // Build out Dropdown nav links (if they exist)
        const dropdownLinks = menuItem.value.sub_nav.filter((val) => {
            return val.type === "snippet_link_with_image";
        });

        if (dropdownLinks.length <= 0) {
            return links;
        }

        return links.concat(
            <DropdownLinks
                linksArray={dropdownLinks}
                linkID={index++}
                key={key++}
            />,
        );
    }

    render() {
        let index = 0;
        const menuLinks = this.props.cmsData.reduce<JSX.Element[]>(
            (memo, menuItem) => {
                switch (menuItem.type) {
                    case "nav_category":
                        return memo.concat(this.buildCategoryLinks(menuItem));
                    case "page_link":
                        return memo.concat(
                            <PageLink
                                linkObject={menuItem}
                                isMobile={this.props.isMobile}
                                linkID={index++}
                                key={menuItem.value.override_title}
                            />,
                        );
                }
                return memo;
            },
            [],
        );
        return <div>{menuLinks}</div>;
    }
}
