import React from "react";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

interface IProps {}

interface IState {}

export class BasketSidebarContent extends React.Component<IProps, IState> {
    render() {
        const phoneLink = (
            <a href={getPageSetting("pre-order-phone-number-link")}>
                {getPageSetting("pre-order-phone-number-display")}
            </a>
        );
        return (
            <div>
                <div className="basket-summary__help">
                    <h3>Questions?</h3>
                    <p>
                        <strong>Call {phoneLink}</strong>.
                    </p>
                </div>
            </div>
        );
    }
}
