import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { IMenuCategoryLink } from "../menu.interface";
import iconCaretDown from "../../svg/caret-down.svg";

interface IProps {
    isMobile: boolean;
    modalIsOpen: boolean;
    linkObject: IMenuCategoryLink;
    openModal: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IState {}

export class CategoryLink extends React.Component<IProps, IState> {
    render() {
        if (this.props.isMobile) {
            return (
                <div
                    key={this.props.linkObject.value.title}
                    className="main-dropdown-menu__mobile main-dropdown-menu__mobile-link"
                >
                    {this.props.linkObject.value.title}
                </div>
            );
        }

        const categoryLinkClass = classNames({
            "site-nav__main-menu-item": true,
            [`site-nav__main-menu-item--${this.props.linkObject.value.title
                .replace(/\s+/g, "-")
                .toLowerCase()}`]: true,
            "site-nav__main-menu-item--hover-state": true,
            "site-nav__main-menu-item--hover-category-link":
                this.props.modalIsOpen,
        });

        return (
            <span key={this.props.linkObject.value.title}>
                <button
                    onClick={(e) => {
                        this.props.openModal(e);
                    }}
                    className={categoryLinkClass}
                >
                    {this.props.linkObject.value.title}
                    <SVG
                        src={iconCaretDown}
                        className="site-nav__shop-icon"
                        aria-hidden="true"
                    />
                </button>
            </span>
        );
    }
}
